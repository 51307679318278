





























































import { defineComponent } from "@vue/composition-api";
import { Button, Cell, CellGroup, Empty, Field, Icon, Switch, Tag } from "vant";
import LocationPicker from "@/components/picker/location-picker.vue";
import axios from "@/helpers/axios";

interface Address {
  id: number;
  name: string;
  phone: string;
  province: string;
  city: string;
  area: string;
  detailAddress: string;
  postalCode: string;
  defaultAddress: boolean;
}

export default defineComponent({
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Switch.name]: Switch,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Icon.name]: Icon,
    LocationPicker
  },
  created() {
    this.getList();
  },
  data() {
    return {
      list: [] as Address[],
      address: { id: -1 } as Address,
      showLocationPicker: false
    };
  },
  methods: {
    toSave(address?: Address) {
      this.address = address || {
        id: 0,
        name: "",
        phone: "",
        province: "",
        city: "",
        area: "",
        detailAddress: "",
        postalCode: "",
        defaultAddress: false
      };
    },
    toList() {
      this.address.id = -1;
      this.getList();
    },
    async getList() {
      const res = await axios
        .post("/api/manage/user/addressList")
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!res) return;
      this.list = res.list;
    },
    async saveAddress() {
      const res = await axios
        .post("/api/manage/user/saveAddress", this.address)
        .then(() => true)
        .catch(() => false);
      if (!res) return;
      this.toList();
    },
    async deleteAddress() {
      const res = await axios
        .post("/api/manage/user/deleteAddress", { addressId: this.address.id })
        .then(() => true)
        .catch(() => false);
      if (!res) return;
      this.toList();
    },
    onPickLocation(location: {
      latitude: number;
      longitude: number;
      detail: {
        address: string;
        area: string;
        city: string;
        province: string;
      };
    }) {
      this.address.province = location.detail.province;
      this.address.area = location.detail.area;
      this.address.city = location.detail.city;
      this.address.detailAddress = location.detail.address;
    },
    onPick(address: Address) {
      this.$emit("onPick", address);
    }
  }
});
