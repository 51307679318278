























import { Button, Cell, CellGroup, Overlay, Search, Toast } from "vant";
import Vue from "vue";
import Component from "vue-class-component";

import AMapLoader from "@amap/amap-jsapi-loader";
import "@amap/amap-jsapi-types";
import { Prop, Watch } from "vue-property-decorator";
declare const AMap: AMap.NameSpace;

interface Location {
  latitude: number;
  longitude: number;
  detail: {
    address: string;
    area: string;
    city: string;
    province: string;
  };
}

interface SearchTip {
  name: string;
  district: string;
  location: AMap.LngLat;
}

@Component({
  components: {
    [Overlay.name]: Overlay,
    [Search.name]: Search,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Button.name]: Button
  }
})
export default class LocationPicker extends Vue {
  @Prop(Boolean) loadDetail!: boolean;
  @Prop(Boolean) value!: boolean;
  @Prop(Number) initLatitude!: number;
  @Prop(Number) initLongitude!: number;

  showSearch = false;
  searchText = "";
  map = undefined as undefined | AMap.Map;
  marker = undefined as undefined | AMap.Marker;
  mass = undefined as undefined | AMap.MassMarks;
  geolocation = undefined as undefined | AMap.Geolocation;
  autoComplete = undefined as undefined | AMap.Autocomplete;
  searchTips = [] as SearchTip[];
  latitude = 0;
  longitude = 0;

  mounted() {
    this.mountMap();
  }

  get showMap() {
    return this.value;
  }

  set showMap(val) {
    this.$emit("input", val);
  }

  mountMap() {
    if (this.map) {
      return;
    }
    const loading = Toast.loading("正在加载地图...");
    AMapLoader.load({
      key: process.env.VUE_APP_AMAP_KEY,
      version: process.env.VUE_APP_AMAP_VERSION,
      plugins: ["AMap.Geolocation"]
    })
      .then(amapLoader => {
        loading.clear();
        this.map = new amapLoader.Map("map", {
          resizeEnable: true,
          zoom: 12,
          rotateEnable: false
        });
        if (this.map) {
          const lnglat = this.map.getCenter();
          this.marker = new AMap.Marker({ position: [lnglat.lng, lnglat.lat] });
          this.map.add(this.marker);
          this.map.on("moveend", () => {
            if (this.map) {
              const lnglat = this.map.getCenter();
              this.marker?.setPosition([lnglat.lng, lnglat.lat]);
            }
          });
          if (this.initLatitude && this.initLongitude) {
            this.map.panTo([this.initLongitude, this.initLatitude]);
          }
        } else {
          this.moveToCurrentLocationOnMap();
        }
      })
      .catch(err => {
        console.log(err);
        this.showMap = false;
        Toast.fail("地图加载失败");
      });
  }
  moveToCurrentLocationOnMap() {
    if (!this.geolocation) {
      this.loadGeolocation();
      return;
    }
    this.geolocation.getCurrentPosition((status: string, result: { info: string; message: string; position: { lng: string; lat: string } }) => {
      if (status != "complete") {
        Toast.fail("定位失败_" + result.info + "," + result.message);
      }
    });
  }
  loadGeolocation() {
    // 加载地图定位
    AMap.plugin(["AMap.Geolocation"], () => {
      const geolocation = new AMap.Geolocation({
        enableHighAccuracy: true,
        timeout: 10000, // ms
        showButton: false
      });
      this.geolocation = geolocation;

      this.map?.addControl(geolocation);

      this.moveToCurrentLocationOnMap();
    });
  }
  @Watch("showSearch")
  onSearchShowHide(show: boolean) {
    if (!show) return;
    if (this.autoComplete) return;
    this.loadAutoComplete();
  }
  loadAutoComplete() {
    const loading = Toast.loading("正在加载地图插件");
    AMap.plugin(["AMap.Autocomplete"], () => {
      loading.clear();
      this.autoComplete = new AMap.Autocomplete();
    });
  }
  onSearch() {
    if (!this.searchText) {
      this.searchTips = [];
      return;
    }
    this.autoComplete?.search(this.searchText, (status: string, result: string | { info: string; count: number; tips: SearchTip[] }) => {
      if (status === "complete" && typeof result !== "string") {
        this.searchTips = result.tips.filter(p => p.location);
      } else {
        Toast.fail(typeof result === "string" ? result : "_搜索失败");
      }
    });
  }
  pickSearchTip(tip: SearchTip) {
    if (!tip.location) Toast.fail("地址不明确");
    else {
      this.map?.setZoomAndCenter(16, tip.location, false);
    }
    this.searchText = "";
    this.searchTips = [];
    this.showSearch = false;
  }
  emitOnPick(extra = {}) {
    this.$emit(
      "onPick",
      Object.assign(
        {
          latitude: this.latitude,
          longitude: this.longitude
        },
        extra
      )
    );
    this.showMap = false;
  }
  pickLocation() {
    if (!this.map) return;
    const center = this.map.getCenter();
    this.latitude = center.lat;
    this.longitude = center.lng;
    if (this.loadDetail) {
      this.inverseAddress([center.lng, center.lat]);
    } else {
      this.emitOnPick();
    }
  }
  inverseAddress(lnglat: number[]) {
    AMap.plugin("AMap.Geocoder", () => {
      const geocoder = new AMap.Geocoder({
        city: "全国"
      });

      geocoder.getAddress(lnglat, (status: string, res: { info: string; regeocode: { addressComponent: { province: string; city: string; district: string; street: string; streetNumber: string } } }) => {
        if (status === "complete" && res.info === "OK") {
          const result = res.regeocode.addressComponent;
          this.emitOnPick({
            detail: {
              province: result.province,
              city: result.city,
              area: result.district,
              address: result.street + result.streetNumber
            }
          });
        } else {
          Toast.fail("获取地址信息失败");
        }
      });
    });
  }
}
